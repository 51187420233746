import { RegisterMethod } from '../auth';
import { ClientMetadataIsNative, ClientMetadataOs } from '../client-metadata';
import { NoteTemplate } from '../note-templates';
import { ReminderInterface } from '../reminders';
import { Spotlight } from '../spotlights';
import { Nullable } from '../typescript';
import { UpdateLogGroup } from '../update-logs';
import { UserActionLimitsInterface } from '../users-action-limits';
import { AdminFeatureFlags } from './admin-feature-flags.enum';
import { CountryCode } from './country.enum';
import { FirstAction } from './first-action.enum';
import { Gender } from './gender.enum';
import { GeneralUse } from './general-use.enum';
import { Hearing } from './hearing.enum';
import { Interest } from './interests.enum';
import { UserPreferences } from './preferences.interface';
import { Profession } from './profession.enum';
import { PrivacyPolicyAcceptedTerms, TermsAndConditionsAcceptedTerms } from './user-accepted-terms';
import { UserFeatureFlags } from './user-feature-flags';
import { UserRegisteredSource } from './user-registered-source.types';
import { UserToken } from './user-token.types';
import { UserType } from './user-type.enum';

export interface UserInterface {
	privateId: string;
	marketingId: string;
	username: string;
	email: string;
	emailVerified: boolean;
	password: Nullable<string>;
	cookieId: Nullable<string>;
	fullName: Nullable<string>;
	firstName: Nullable<string>;
	lastName: Nullable<string>;
	telephone: Nullable<string>;
	birthday: Nullable<Date>;
	country: Nullable<CountryCode>;
	profession: Nullable<Profession>;
	hearing: Nullable<string>;
	cleanHearing: Nullable<Hearing>;
	registerLocation: Nullable<string>;
	registerIP: Nullable<string>;
	registeredAt: Date;
	activatedAt: Nullable<Date>;
	generalUse: Nullable<GeneralUse>;
	firstAction: Nullable<FirstAction>;
	lastAccess: Nullable<Date>;
	type: UserType;
	avatarResourceId: Nullable<string>;
	gender: Nullable<Gender>;
	interests: Nullable<Interest[]>;
	selectedNoteTemplates: NoteTemplate[];
	googleId: Nullable<string>;
	appleId: Nullable<string>;
	azureId: Nullable<string>;
	registerMethod: RegisterMethod;
	adminFeatureFlags: AdminFeatureFlags;
	userFeatureFlags: UserFeatureFlags[];
	updatedAt: Date;
	completedOnboarding: boolean;
	registeredSource: UserRegisteredSource;
	registeredOS: ClientMetadataOs;
	registeredNative: ClientMetadataIsNative;
	discourseId: Nullable<string>;
	pinnedSearchParams: string[];
}

export type UserWithoutSensitive = Omit<
	UserInterface,
	'password' | 'registerIp' | 'registerLocation' | 'googleId' | 'appleId' | 'azureId' | 'cookieId'
> & { imageUrl: Nullable<string>; thumbnailUrl: Nullable<string> };

export type UserPublicInfo = Pick<
	UserInterface,
	| 'username'
	| 'email'
	| 'fullName'
	| 'firstName'
	| 'lastName'
	| 'birthday'
	| 'telephone'
	| 'country'
	| 'gender'
	| 'interests'
	| 'profession'
> & { imageUrl: Nullable<string>; thumbnailUrl: Nullable<string> };

export interface UserRelations {
	settings: UserPreferences;
	privacyPolicyAcceptedTerms: PrivacyPolicyAcceptedTerms[];
	termsAndConditionsAcceptedTerms: TermsAndConditionsAcceptedTerms[];
	userTokens: UserToken[];
	seenTutorialsAndSpotlights: Omit<Spotlight, 'seenByCount'>[];
	updateRead: UpdateLogGroup;
	reminders: ReminderInterface[];
	actionLimits: UserActionLimitsInterface;
}

export type UserWithRelationsInterface<K extends keyof UserRelations> = UserInterface &
	Pick<UserRelations, K>;

export type FullUserInterface = UserWithRelationsInterface<keyof UserRelations>;
